import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import IdeaDetails from '../components/IdeaDetails';

const IdeaDetailsPage = () => {
  const { id } = useParams(); // Get the ID from the route parameters
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the idea from the backend using the ID from the URL
    axios.get(`${process.env.REACT_APP_BACKEND}/all/ideas/${id}`)
      .then(response => {
        setIdea(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching idea details');
        setLoading(false);
      });
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      {/* Pass the fetched idea to the IdeaDetails component */}
      {idea ? <IdeaDetails idea={idea} /> : <p>No idea found</p>}
    </div>
  );
}

export default IdeaDetailsPage;
