import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SocialsIdeaIconsForModal from '../IdeaDetails/SocialsIdeaIconsForModal';
import "./css/IdeaSubmissionModal.css";

const WinModal = ({ modalShow, setModalShow, idea_id }) => {
  const navigate = useNavigate();

  // Function to handle the "Pitch My Idea" button
  const handlePitchMyIdea = () => {
    setModalShow(false); // Close modal
    navigate('/pitch'); // Navigate to the pitch page
  };

  // Function to handle the "Vote Ideas" button
  const handleViewAllIdea = () => {
    setModalShow(false); // Close modal
    navigate("/"); // Navigate to the home page
  };

  return (
    <Modal
      open={modalShow}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={() => setModalShow(false)} // Close the modal when clicking outside
    >
      <Box className="idea-modal-container">
        <img src="/home/win.png" alt="Thank You" className="idea-modal-image" />

        <p className="idea-modal-title">Monthly Idea Contest!</p>

        <div className="your-idea-has-been-pitched-main">
          <div className="your-idea-has-been-pitched">
            Top 3 Ideas with the Most Votes Win Exclusive Rewards
          </div>
        </div>

        <Typography className="idea-modal-message">
          Pitch your innovative ideas or vote for others and help shape the future of Bangladesh. Every month, the top 3 ideas with the most votes will be crowned winners!
        </Typography>

        <SocialsIdeaIconsForModal ideaId={idea_id} />

        <div className="idea-modal-button-group">
          <button
            className="idea-modal-back-btn"
            style={{ marginRight: "20px" }}
            onClick={handlePitchMyIdea}
          >
            Pitch My Idea
          </button>
          <button
            className="idea-modal-view-btn"
            onClick={handleViewAllIdea}
          >
            Vote Ideas
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default WinModal;
