import { FETCH_IDEA_COUNT_FAIL, FETCH_IDEA_COUNT_REQUEST, FETCH_IDEA_COUNT_SUCCESS, FETCH_IDEA_FAIL, FETCH_IDEA_REQUEST, FETCH_IDEA_SUCCESS, FETCH_TOP_IDEA_FAIL, FETCH_TOP_IDEA_REQUEST, FETCH_TOP_IDEA_SUCCESS } from "../constants/IdeaConstants";

export const IdeasFilteredInfoReducer = (state = {}, action) => {
    switch (action.type) {
      case FETCH_IDEA_REQUEST:
        return { Ideasloading: true };
      case FETCH_IDEA_SUCCESS:
        return { Ideasloading: false, ideas: action.payload };
      case FETCH_IDEA_FAIL:
        return { Ideasloading: false, IdeasError: action.payload };
      default:
        return state;
    }
  };


  export const FetchTopIdeasReducer = (state = {}, action) => {
    switch (action.type) {
      case FETCH_TOP_IDEA_REQUEST:
        return { TopIdeasloading: true };
      case FETCH_TOP_IDEA_SUCCESS:
        return { TopIdeasloading: false, TopIdeas: action.payload };
      case FETCH_TOP_IDEA_FAIL:
        return { TopIdeasloading: false, TopIdeasError: action.payload };
      default:
        return state;
    }
  };


  export const IdeasCountInfoReducer = (state = {}, action) => {
    switch (action.type) {
      case FETCH_IDEA_COUNT_REQUEST:
        return { CountIdeasloading: true };
      case FETCH_IDEA_COUNT_SUCCESS:
        return { CountIdeasloading: false, CountIdeas: action.payload };
      case FETCH_IDEA_COUNT_FAIL:
        return { CountIdeasloading: false, CountIdeasError: action.payload };
      default:
        return state;
    }
  };