import React, { useState } from 'react';
import "../css/IdeaDetails.css"
import { FacebookShareButton, FacebookIcon } from 'react-share';

const SocialsIdeaIconsForModal = (props) =>{
    const [copySuccess, setCopySuccess] = useState('');

    const handleCopyLink = () => {
      // Define the link or text you want to copy
      const linkToCopy = process.env.REACT_APP_FRONTEND+"/idea/"+props.ideaId; // Current URL, you can replace this with any link
  
      // Copy to clipboard
      navigator.clipboard.writeText(linkToCopy).then(() => {
        setCopySuccess('Link copied!');
        setTimeout(() => setCopySuccess(''), 2000); // Clear success message after 2 seconds
      }).catch((err) => {
        console.error('Failed to copy the text: ', err);
      });
    };

    const shareUrl = process.env.REACT_APP_FRONTEND+"/idea/"+props.ideaId; // The URL to share
    const title = "Check out this awesome page!"; // Title to share

    const HandleShareToInstagram = () => {
      const url = process.env.REACT_APP_FRONTEND+"/idea/"+props.ideaId; // Current page URL
      const text = "Poriborton Chai";
      const instagramShareUrl = `https://www.instagram.com/create/story/?media=${url}&caption=${text}`
      navigator.clipboard.writeText(url).then(() => {
        window.open(instagramShareUrl, '_blank', 'noopener,noreferrer');
      });
    };

    const HandleTelegram = () =>{
      const shareUrl = encodeURIComponent(process.env.REACT_APP_FRONTEND+"/idea/"+props.ideaId);
      const telegramShareUrl = `https://t.me/share/url?url=${shareUrl}`;
      navigator.clipboard.writeText(shareUrl).then(() => {
        window.open(telegramShareUrl, '_blank', 'noopener,noreferrer');
      });
    }

    const ShareToWhatsApp = () => {
      const shareText = encodeURIComponent("Check out this idea: ");
      const shareUrl = encodeURIComponent(process.env.REACT_APP_FRONTEND+"/idea/"+props.ideaId); // Current page URL
      const whatsappShareUrl = `https://api.whatsapp.com/send?text=${shareText}${shareUrl}`;

      navigator.clipboard.writeText(shareUrl).then(() => {
        window.open(whatsappShareUrl, '_blank', 'noopener,noreferrer');
      });
    }
    
    const ShareToLinkedIn = () => {
      const shareUrl = encodeURIComponent(process.env.REACT_APP_FRONTEND+"/idea/"+props.ideaId); // Current page URL
      const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`;

      navigator.clipboard.writeText(shareUrl).then(() => {
        window.open(linkedInShareUrl, '_blank', 'noopener,noreferrer');
      });
    }    

    const ShareToTwitter = () => {
      const shareUrl = encodeURIComponent(process.env.REACT_APP_FRONTEND+"/idea/"+props.ideaId); // Current page URL
      const text = encodeURIComponent('Check out this amazing content!'); // Optional text to prefill in the tweet
      const hashtags = encodeURIComponent('Example,ReactJS'); // Optional hashtags
      const twitterShareUrl = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${text}&hashtags=${hashtags}`;

      navigator.clipboard.writeText(shareUrl).then(() => {
        window.open(twitterShareUrl, '_blank', 'noopener,noreferrer');
      });
    }
    
   
    const handleEmailClick = () => {
      const recipient = "someone@example.com"; // Replace with the actual recipient's email
      const subject = "Check out this idea!";  // Email subject
      const body = `Hi there,\n\nCheck out this amazing idea: ${process.env.REACT_APP_FRONTEND}/idea/${props.ideaId}\n\nBest regards,`; // Email body with the idea link
    
      // Construct the mailto link
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
      // Open the mailto link by assigning it to window.location
      window.location.href = mailtoLink;
    };
    
    
    return(
        <div className="ideas-details-frame-1171277473">
        <div className="ideas-details-frame-1171277472">
          <div className="ideas-details-link-1-1" onClick={handleCopyLink} style={{ cursor: 'pointer' }}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_494_856)">
            <path d="M8.61197 11.0598L6.1639 13.508C6.1639 13.508 6.1639 13.508 6.16384 13.508C6.16384 13.508 6.16384 13.5081 6.16378 13.5081C5.15143 14.5205 3.50411 14.5206 2.49164 13.5081C2.00116 13.0176 1.73111 12.3655 1.73111 11.672C1.73111 10.9785 2.00116 10.3265 2.49147 9.83601C2.49153 9.83595 2.49158 9.83589 2.49164 9.83583L4.93972 7.3877C5.27769 7.04967 5.27769 6.5016 4.93966 6.16363C4.60169 5.82566 4.05362 5.82566 3.71559 6.16363L1.26752 8.61177C1.26734 8.61194 1.26717 8.61217 1.267 8.61234C0.449973 9.42966 0 10.5163 0 11.672C0 12.8279 0.450146 13.9147 1.26758 14.7322C2.11132 15.5758 3.21952 15.9977 4.32777 15.9977C5.43603 15.9977 6.54428 15.5758 7.38791 14.7322C7.38797 14.7322 7.38797 14.732 7.38797 14.732L9.83604 12.2839C10.174 11.9459 10.174 11.3979 9.83598 11.0598C9.49807 10.7219 8.95006 10.7219 8.61197 11.0598Z" fill="#4548E3"/>
            <path d="M15.9999 4.32765C15.9999 3.17168 15.5497 2.08489 14.7323 1.26746C13.0448 -0.419911 10.2992 -0.419853 8.61193 1.26746C8.61187 1.26757 8.61175 1.26763 8.6117 1.26775L6.16368 3.71571C5.82565 4.05368 5.82565 4.6018 6.16368 4.93977C6.33275 5.10885 6.55422 5.19332 6.77574 5.19332C6.99721 5.19332 7.21879 5.10879 7.38774 4.93977L9.83576 2.49181C9.83582 2.4917 9.83594 2.49164 9.83605 2.49152C10.8484 1.47917 12.4957 1.47912 13.5082 2.49152C13.9986 2.98201 14.2688 3.63411 14.2688 4.32765C14.2688 5.02114 13.9987 5.67313 13.5084 6.16361L13.5082 6.16378L11.0601 8.61192C10.7221 8.94989 10.7221 9.49795 11.0602 9.83598C11.2292 10.005 11.4507 10.0895 11.6722 10.0895C11.8937 10.0895 12.1152 10.005 12.2842 9.83598L14.7323 7.38785C14.7325 7.38768 14.7327 7.38744 14.7328 7.38727C15.5499 6.56996 15.9999 5.48334 15.9999 4.32765Z" fill="#4548E3"/>
            <path d="M4.93907 11.0598C5.10808 11.2289 5.32961 11.3134 5.55107 11.3134C5.7726 11.3134 5.99412 11.2289 6.16313 11.0598L11.0594 6.16358C11.3974 5.82561 11.3974 5.27754 11.0594 4.93951C10.7214 4.60154 10.1734 4.60154 9.83533 4.93951L4.93907 9.83572C4.60104 10.1738 4.60104 10.7219 4.93907 11.0598Z" fill="#4548E3"/>
            </g>
            <defs>
            <clipPath id="clip0_494_856">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          </div>
        </div>
        <div className="ideas-details-facebook-2-1" style={{ cursor: 'pointer' }}>
            <FacebookShareButton
            url={shareUrl}
            quote={title} // Optional: a quote to share with the URL
            hashtag="#AwesomePage" // Optional: a hashtag to include
            className="share-button"
          >
            <FacebookIcon size={36} round /> {/* Facebook icon with size and round option */}
          </FacebookShareButton>
        </div>
        <div className="ideas-details-messenger-1" style={{ cursor: 'pointer' }}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_494_865)">
            <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#0084FF"/>
            <path d="M29.25 17.5767C29.25 23.3305 24.212 27.9942 18 27.9942C16.9094 27.9949 15.8237 27.8483 14.7723 27.5585L10.9411 29.6592V25.6869C8.38636 23.7785 6.75 20.8637 6.75 17.5767C6.75 11.8228 11.788 7.15918 18 7.15918C24.212 7.15918 29.25 11.8249 29.25 17.5767Z" fill="white"/>
            <path d="M25.2667 14.6597L19.116 21.1847L16.2523 18.1308L10.6621 21.1867L16.8128 14.6597L19.748 17.7135L25.2667 14.6597Z" fill="#2196F3"/>
            </g>
            <defs>
            <clipPath id="clip0_494_865">
            <rect width="36" height="36" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div className="ideas-details-instagram-2" onClick={HandleShareToInstagram} style={{ cursor: 'pointer' }}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_494_872)">
            <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="url(#paint0_linear_494_872)"/>
            <path d="M21.8238 8H14.1838C10.7758 8 8.00781 10.768 8.00781 14.176V21.816C8.00781 25.224 10.7758 27.992 14.1838 27.992H21.8238C25.2318 27.992 27.9998 25.224 27.9998 21.816V14.176C27.9998 10.768 25.2318 8 21.8238 8ZM25.7678 21.824C25.7678 24 23.9998 25.776 21.8158 25.776H14.1758C11.9998 25.776 10.2238 24.008 10.2238 21.824V14.184C10.2238 12.008 11.9918 10.232 14.1758 10.232H21.8158C23.9918 10.232 25.7678 12 25.7678 14.184V21.824Z" fill="white"/>
            <path d="M18.0007 12.8877C15.1847 12.8877 12.8887 15.1837 12.8887 17.9997C12.8887 20.8157 15.1847 23.1117 18.0007 23.1117C20.8167 23.1117 23.1127 20.8157 23.1127 17.9997C23.1127 15.1837 20.8167 12.8877 18.0007 12.8877ZM18.0007 21.1037C16.2887 21.1037 14.8967 19.7117 14.8967 17.9997C14.8967 16.2877 16.2887 14.8957 18.0007 14.8957C19.7127 14.8957 21.1047 16.2877 21.1047 17.9997C21.1047 19.7117 19.7127 21.1037 18.0007 21.1037Z" fill="white"/>
            <path d="M23.5003 13.4482C23.9713 13.3718 24.2912 12.9281 24.2149 12.4571C24.1385 11.9862 23.6948 11.6663 23.2239 11.7426C22.7529 11.819 22.433 12.2627 22.5093 12.7336C22.5857 13.2046 23.0294 13.5245 23.5003 13.4482Z" fill="white"/>
            </g>
            <defs>
            <linearGradient id="paint0_linear_494_872" x1="4.29432" y1="31.7057" x2="29.8743" y2="6.12568" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FEE411"/>
            <stop offset="0.0518459" stop-color="#FEDB16"/>
            <stop offset="0.1381" stop-color="#FEC125"/>
            <stop offset="0.2481" stop-color="#FE983D"/>
            <stop offset="0.3762" stop-color="#FE5F5E"/>
            <stop offset="0.5" stop-color="#FE2181"/>
            <stop offset="1" stop-color="#9000DC"/>
            </linearGradient>
            <clipPath id="clip0_494_872">
            <rect width="36" height="36" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div className="ideas-details-whatsapp-1" onClick={HandleTelegram} style={{ cursor: 'pointer' }}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_494_879)">
            <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#039BE5"/>
            <path d="M8.23738 17.6098L25.5924 10.9183C26.3979 10.6273 27.1014 11.1148 26.8404 12.3328L26.8419 12.3313L23.8869 26.2528C23.6679 27.2398 23.0814 27.4798 22.2609 27.0148L17.7609 23.6983L15.5904 25.7893C15.3504 26.0293 15.1479 26.2318 14.6829 26.2318L15.0024 21.6523L23.3424 14.1178C23.7054 13.7983 23.2614 13.6183 22.7829 13.9363L12.4764 20.4253L8.03338 19.0393C7.06888 18.7333 7.04788 18.0748 8.23738 17.6098Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_494_879">
            <rect width="36" height="36" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div className="ideas-details-linkedin-8-1" style={{ cursor: 'pointer' }} onClick={ShareToWhatsApp}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_494_882)">
            <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#29A71A"/>
            <path d="M25.9357 10.0635C24.063 8.17203 21.5759 7.01208 18.9233 6.79283C16.2706 6.57358 13.6269 7.30948 11.469 8.86778C9.31117 10.4261 7.78118 12.7042 7.15504 15.2912C6.52891 17.8782 6.84785 20.6038 8.05434 22.9764L6.87002 28.7262C6.85773 28.7834 6.85738 28.8426 6.869 28.8999C6.88061 28.9573 6.90394 29.0117 6.93752 29.0596C6.98671 29.1324 7.05693 29.1884 7.1388 29.2202C7.22067 29.252 7.31029 29.258 7.3957 29.2376L13.0309 27.9019C15.3968 29.0778 18.1032 29.3763 20.6685 28.7441C23.2338 28.1119 25.4916 26.5901 27.0402 24.4495C28.5887 22.3089 29.3276 19.6883 29.1254 17.054C28.9232 14.4197 27.7929 11.9426 25.9357 10.0635ZM24.1787 24.0871C22.8829 25.3792 21.2144 26.2322 19.4082 26.5257C17.602 26.8193 15.7492 26.5387 14.1109 25.7235L13.3255 25.3348L9.8707 26.153L9.88093 26.1101L10.5968 22.6328L10.2123 21.8739C9.37521 20.2299 9.07992 18.3632 9.36872 16.5411C9.65752 14.719 10.5156 13.0351 11.82 11.7305C13.4591 10.092 15.6818 9.17151 17.9993 9.17151C20.3169 9.17151 22.5396 10.092 24.1787 11.7305C24.1926 11.7465 24.2076 11.7616 24.2237 11.7755C25.8424 13.4183 26.746 15.6343 26.7376 17.9406C26.7292 20.2468 25.8093 22.4562 24.1787 24.0871Z" fill="white"/>
            <path d="M23.8718 21.5366C23.4483 22.2034 22.7795 23.0195 21.9388 23.222C20.4661 23.5779 18.2058 23.2343 15.3933 20.612L15.3586 20.5813C12.8856 18.2884 12.2433 16.38 12.3988 14.8663C12.4847 14.0072 13.2006 13.23 13.804 12.7227C13.8994 12.6413 14.0125 12.5833 14.1344 12.5534C14.2562 12.5235 14.3833 12.5226 14.5055 12.5506C14.6278 12.5786 14.7418 12.6349 14.8384 12.7148C14.935 12.7948 15.0116 12.8963 15.062 13.0111L15.9722 15.0566C16.0314 15.1892 16.0533 15.3354 16.0356 15.4796C16.018 15.6237 15.9614 15.7603 15.872 15.8747L15.4118 16.472C15.313 16.5953 15.2534 16.7454 15.2407 16.9029C15.2279 17.0603 15.2626 17.218 15.3402 17.3556C15.5979 17.8077 16.2156 18.4725 16.9008 19.0881C17.6699 19.7836 18.5229 20.4197 19.0629 20.6366C19.2074 20.6956 19.3662 20.71 19.519 20.6779C19.6718 20.6458 19.8114 20.5687 19.9199 20.4566L20.4538 19.9186C20.5568 19.817 20.6849 19.7446 20.8251 19.7086C20.9652 19.6727 21.1123 19.6746 21.2515 19.7141L23.4136 20.3277C23.5328 20.3643 23.6422 20.4276 23.7332 20.513C23.8242 20.5983 23.8945 20.7033 23.9387 20.8199C23.9829 20.9366 23.9999 21.0618 23.9883 21.186C23.9767 21.3102 23.9368 21.4301 23.8718 21.5366Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_494_882">
            <rect width="36" height="36" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div className="ideas-details-twitter-2-1" onClick={ShareToLinkedIn} style={{ cursor: 'pointer' }} >
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_494_889)">
            <path d="M18 35.9995C27.9411 35.9995 36 27.9406 36 17.9995C36 8.05839 27.9411 -0.000488281 18 -0.000488281C8.05888 -0.000488281 0 8.05839 0 17.9995C0 27.9406 8.05888 35.9995 18 35.9995Z" fill="#007AB9"/>
            <path d="M28.7532 19.4481V26.8691H24.4507V19.9455C24.4507 18.207 23.8295 17.0198 22.2717 17.0198C21.0829 17.0198 20.3766 17.8191 20.0647 18.593C19.9515 18.8696 19.9223 19.2537 19.9223 19.6416V26.8688H15.6195C15.6195 26.8688 15.6772 15.1424 15.6195 13.9286H19.9226V15.7623C19.9139 15.7768 19.9017 15.7909 19.894 15.8047H19.9226V15.7623C20.4944 14.8825 21.5141 13.6247 23.8003 13.6247C26.631 13.6247 28.7532 15.4742 28.7532 19.4481ZM11.1183 7.69092C9.64652 7.69092 8.68359 8.65705 8.68359 9.9264C8.68359 11.1688 9.6186 12.1628 11.0619 12.1628H11.0898C12.5905 12.1628 13.5236 11.1688 13.5236 9.9264C13.495 8.65705 12.5905 7.69092 11.1183 7.69092ZM8.93932 26.8691H13.2405V13.9286H8.93932V26.8691Z" fill="#F1F2F2"/>
            </g>
            <defs>
            <clipPath id="clip0_494_889">
            <rect width="36" height="36" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div className="ideas-details-twitter-2-1" onClick={ShareToTwitter} style={{ cursor: 'pointer' }}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_494_894)">
            <path d="M18 0C8.05922 0 0 8.05922 0 18C0 27.9408 8.05922 36 18 36C27.9408 36 36 27.9408 36 18C36 8.05922 27.9408 0 18 0Z" fill="black"/>
            <path d="M19.9709 16.3712L27.6765 7.41406H25.8505L19.1598 15.1914L13.8159 7.41406H7.65234L15.7333 19.1748L7.65234 28.5677H9.47843L16.5441 20.3546L22.1876 28.5677H28.3511L19.9704 16.3712H19.9709ZM10.1364 8.78872H12.9411L25.8514 27.2555H23.0466L10.1364 8.78872Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_494_894">
            <rect width="36" height="36" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div className="ideas-details-twitter-2-1" onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_494_898)">
              <path d="M35.5379 17.8671C35.5379 8.10751 27.6262 0.195801 17.8666 0.195801C8.10702 0.195801 0.195312 8.10751 0.195312 17.8671V18.1332C0.195312 27.8928 8.10702 35.8045 17.8666 35.8045C27.6262 35.8045 35.5379 27.8928 35.5379 18.1332V17.8671Z" fill="white"/>
              <path d="M8.85614 25.8261H12.1755V17.7649L7.43359 14.2085V24.4036C7.43359 25.1907 8.07138 25.8261 8.85614 25.8261Z" fill="#4285F4"/>
              <path d="M23.5586 25.8261H26.8779C27.6651 25.8261 28.3005 25.1884 28.3005 24.4036V14.2085L23.5586 17.7649" fill="#34A853"/>
              <path d="M23.5586 11.6004V17.7649L28.3005 14.2085V12.3117C28.3005 10.5525 26.2923 9.54958 24.8863 10.6046" fill="#FBBC04"/>
              <path d="M12.1758 17.765V11.6006L17.8661 15.8683L23.5563 11.6006V17.765L17.8661 22.0328" fill="#EA4335"/>
              <path d="M7.43359 12.3117V14.2085L12.1755 17.7649V11.6004L10.8478 10.6046C9.43942 9.54958 7.43359 10.5525 7.43359 12.3117Z" fill="#C5221F"/>
              <path d="M35.5379 17.8671C35.5379 8.10751 27.6262 0.195801 17.8666 0.195801C8.10702 0.195801 0.195312 8.10751 0.195312 17.8671V18.1332C0.195312 27.8928 8.10702 35.8045 17.8666 35.8045C27.6262 35.8045 35.5379 27.8928 35.5379 18.1332V17.8671Z" stroke="#C4CFE3"/>
              </g>
              <defs>
              <clipPath id="clip0_494_898">
              <rect width="36" height="36" fill="white"/>
              </clipPath>
              </defs>
          </svg>
        </div>
      </div>
    )
}

export default SocialsIdeaIconsForModal;