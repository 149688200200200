import Axios from 'axios'
import { FETCH_IDEA_COUNT_FAIL, FETCH_IDEA_COUNT_REQUEST, FETCH_IDEA_COUNT_SUCCESS, FETCH_IDEA_FAIL, FETCH_IDEA_REQUEST, FETCH_IDEA_SUCCESS, FETCH_TOP_IDEA_FAIL, FETCH_TOP_IDEA_REQUEST, FETCH_TOP_IDEA_SUCCESS } from '../constants/IdeaConstants';

export const FetchIdeaWithPageNumberAndLimit = (pageNumber,limit,categoryId) => async (dispatch) => {
  dispatch({ type: FETCH_IDEA_REQUEST});
    try {
      const { data } = await Axios.get(`${process.env.REACT_APP_BACKEND}/all/ideas?page=${pageNumber}&limit=${limit}&categoryId=${categoryId}`);
      dispatch({ type: FETCH_IDEA_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: FETCH_IDEA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
};



export const FetchTopIdeas = (limit) => async (dispatch) => {
  dispatch({ type: FETCH_TOP_IDEA_REQUEST});
  try {
    const { data } = await Axios.get(`${process.env.REACT_APP_BACKEND}/all/ideas/top-voted?limit=${limit}`);
    dispatch({ type: FETCH_TOP_IDEA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FETCH_TOP_IDEA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const FetchIdeaCount = (pageNumber,limit,categoryId) => async (dispatch) => {
  dispatch({ type: FETCH_IDEA_COUNT_REQUEST});
    try {
      const { data } = await Axios.get(`${process.env.REACT_APP_BACKEND}/all/ideas?page=${pageNumber}&limit=${limit}&categoryId=${categoryId}`);
      dispatch({ type: FETCH_IDEA_COUNT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: FETCH_IDEA_COUNT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
};