import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './css/IdeaDetails.css';
import SocialIdeaDetails from './IdeaDetails/SocialsIdeaDetails';
import './css/ReportIdea.css';
import InformingUser from './Modal/Inform';
import { useDispatch, useSelector } from "react-redux";
import { userVoteIdeaCount } from '../actions/UserActions';

const IdeaDetails = () => {
  const { id } = useParams(); // Use useParams to get the idea ID from the URL
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasVoted, setHasVoted] = useState(false); // Assume no votes initially
  const [hasReported, setHasReported] = useState(false); // Assume no reports initially
  const [totalVotes, setTotalVotes] = useState(0);
  const [totalReport, setTotalReport] = useState(0); // Track total reports
  const [showReportModal, setShowReportModal] = useState(false); // Modal visibility
  const [reportType, setReportType] = useState('');
  const [customReport, setCustomReport] = useState('');
  const [votedTheIdea, setVotedTheIdea] = useState(false);
  const [modalShow, setModalshow] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [totalLikes, setTotalLikes] = useState(0); // To track total likes
  const [totalDislikes, setTotalDislikes] = useState(0); // To track total dislikes
  const [isLiked, setIsLiked] = useState(false);
  const [isDisLiked, setIsDisLiked] = useState(false);
  const [totalVoteCountUniqueUser,settotalVoteCountUniqueUser] = useState(0);

  const userInfo = JSON.parse(localStorage.getItem('PoribortonchaiUserData'));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const totalVotesCountUniqueUser = useSelector((state) => state.userTotalVoteCount);
  let { loadingUserTotalVoteCount, errorUserTotalVoteCount, userInfoVoteCount } = totalVotesCountUniqueUser;

  useEffect(() => {
    // Dispatch the userVoteIdeaCount action to fetch vote count
    dispatch(userVoteIdeaCount());
  }, [dispatch]); // This will run once after component mount to dispatch the action

  useEffect(() => {
    // Set totalVoteCountUniqueUser once userInfoVoteCount updates
    if (userInfoVoteCount && userInfoVoteCount.monthlyVotesLength !== undefined) {
      settotalVoteCountUniqueUser(userInfoVoteCount.monthlyVotesLength);
    }
  }, [userInfoVoteCount,totalVoteCountUniqueUser]); // This useEffect runs whenever userInfoVoteCount is updated

  
  // Fetch idea details and check if the user has already voted
  useEffect(() => {
    const fetchIdeaDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/all/ideas/${id}`);
        const data = await response.json();
        setIdea(data);
        setTotalVotes(data.voteCount);
        setTotalLikes(data.likeCount);  // Set total likes
        setTotalDislikes(data.dislikeCount);  // Set total dislikes
        setTotalReport(data.reportCount);
        setLoading(false);
      } catch (error) {
        setError('Error fetching idea details');
        setLoading(false);
      }
    };

    fetchIdeaDetails();
  }, [id]);

  // Fetch vote status only once
  useEffect(() => {
    const checkUserVoteStatus = async () => {
      if (userInfo && id) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/idea/${id}/has-voted`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userInfo._id }),
            credentials: 'include',
          });
          const voteData = await response.json();
         
          if (voteData.hasVoted) {
            setHasVoted(true);
            
            if(voteData.type == "like")
              setIsLiked(true);
              setIsDisLiked(false);
            } 
            if(voteData.type == "dislike"){
              setIsLiked(false);
              setIsDisLiked(true);
            }
        } catch (error) {
          console.error('Error checking vote status:', error);
        }
      }
    };

    checkUserVoteStatus();
  }, [id, userInfo]); // Ensure it only runs once per user and idea

  // Vote for the idea
  const handleVote = (type) => {
    //alert(totalVoteCountUniqueUser);
    settotalVoteCountUniqueUser((prevCount) => prevCount - 1);

    if (type == 'like') {
      setIsLiked(true);
      setIsDisLiked(false);
    }

    if (type == 'dislike') {
      setIsLiked(false);
      setIsDisLiked(true);
    }

    if (!userInfo) {
      navigate('/login?idea=idea/' + idea._id);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND}/user/get-user-data`, {
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 401) {
          navigate('/login?idea=idea/' + idea._id);
          throw new Error('Unauthorized');
        }
        if (response.status === 403) {
          response.json().then((errorData) => {
            setModalshow(true);
            setModalContent(errorData.error);
          });
          return;
        }
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        return response.json();
      })
      .then((data) => {
        const { userProfile } = data;
        const { name, occupation, currentCity, dob } = userProfile;

        // Check if user has completed the profile
        if (!name || !occupation || !currentCity || !dob) {
          setModalshow(true);
          setModalContent('You must complete your profile before voting.');
          setTimeout(() => {
            navigate('/profile?page=idea' + '&idea=' + idea._id);
            return;
          }, 2500);
        }

        const formData = {
          userId: userInfo._id,
          type: type
        }

        fetch(`${process.env.REACT_APP_BACKEND}/user/idea/${idea._id}/vote`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',  // Set the content type to JSON
          },
          body: JSON.stringify(formData),
          credentials: 'include',
        })
          .then((response) => {
            if (response.satus === 401) {
              navigate('/login?idea=idea/' + idea._id);
              throw new Error('Unauthorized');
            }
            if (response.status === 403) {
              response.json().then((errorData) => {
                setModalshow(true);
                setModalContent(errorData.error);
              });
              return;
            }
            if (response.ok) {
             
              return response.json();
            } else {
              throw new Error('Failed to submit the vote');
            }
          })
          .then((data) => {
            setTotalVotes((prevVotes) => prevVotes + 1); // Update total votes
            if (type === 'like') {
              setTotalLikes((prevLikes) => prevLikes + 1); // Update like count
            } else if (type === 'dislike') {
              setTotalDislikes((prevDislikes) => prevDislikes + 1); // Update dislike count
            }
            setHasVoted(true);  // Prevent further votes
            setVotedTheIdea(true);
          })
          .catch((error) => console.error(`Error submitting ${type} for idea:`, error));
      })
      .catch((error) => console.error('Error fetching user data:', error));

  
  };


  const handleSignIn = () =>{
    navigate('/login?idea=idea/' + idea._id);
  }

  // Open the report modal
  const openReportModal = () => {
    setShowReportModal(true);
  };

  // Close the report modal
  const closeReportModal = () => {
    setShowReportModal(false);
    setReportType(''); // Reset report type
    setCustomReport(''); // Reset custom report
  };

  const selectRadiobtnDiv = () => {
    return (
      <div className="selectRadiobtnDivMain">
        <div className="selectRadiobtnDiv"></div>
      </div>
    )
  }
  // Handle the report submission
  const handleReportSubmit = () => {
    const types = reportType === 'Other' ? customReport : reportType;

    if (!types) {
      setModalshow(true);
      setModalContent('Please select a type');
      return;
    }

    if (!userInfo) {
      setModalshow(true);
      setModalContent('Your session has expired. Please log in again.');
      navigate('/login?idea=idea/' + idea._id);
      return;
    }

    // Fetch the user profile data to check if the user has completed their profile
    fetch(`${process.env.REACT_APP_BACKEND}/user/get-user-data`, {
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 401) {
          setModalshow(true);
          setModalContent('Your session has expired. Please log in again.');
          navigate('/login?idea=idea/' + idea._id);
          throw new Error('Unauthorized');
        }
        if (response.status === 403) {
          response.json().then((errorData) => {
            console.log(errorData.error);
            setModalshow(true);
            setModalContent(errorData.error);
          });
          return;
        }
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json(); // Process the user data
      })
      .then((data) => {
        const { userProfile } = data; // Get user profile data
        const { name, occupation, currentCity, dob } = userProfile;

        // Check if any required fields are missing
        if (!name || !occupation || !currentCity || !dob) {
          setModalshow(true);
          setModalContent('You must complete your profile before reporting.');
          navigate('/profile'); // Redirect to profile page for editing
          return;
        }

        // If the profile is complete, proceed with reporting
        const bodyData = {
          userId: userInfo._id,
          types,
        };

        fetch(`${process.env.REACT_APP_BACKEND}/user/idea/${idea._id}/report`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
          credentials: 'include',
        })
          .then((response) => {
            if (response.status === 401) {
              setModalshow(true);
              setModalContent('Your session has expired. Please log in again.');
              navigate('/login?idea=idea/' + idea._id);
              throw new Error('Unauthorized');
            }
            if (response.status === 403) {
              response.json().then((errorData) => {
                console.log(errorData.error);
                setModalshow(true);
                setModalContent(errorData.error);
              });
              return;
            }
            if (!response.ok) {
              throw new Error('Failed to report the idea');
            }
            return response.json(); // Process the response JSON here
          })
          .then((data) => {
            if (data.status === 200) {
              setTotalReport(totalReport + 1); // Update total report count
              setHasReported(true);
              setShowReportModal(false); // Close the modal after the report is submitted
              console.log('Idea reported successfully');
              setModalshow(true);
              setModalContent('Idea reported successfully');
            }
          })
          .catch((error) => console.error('Error reporting idea:', error));
      })
      .catch((error) => console.error('Error fetching user data:', error));
    closeReportModal();
  };


  if (loading) return <div style={{ minHeight: "1200px", margin: "20px" }}>Loading idea details...</div>;
  if (error) return <div style={{ minHeight: "1200px", margin: "20px" }}>{error}</div>;

  return (
    <>
      {modalShow && <InformingUser modalContents={modalContent} setModalShow={setModalshow} />}
      <div className="ideas-details-container">
        <div className="ideas-details-left-div">
          <div className="ideas-details-frame-1171277321">
            <div className="ideas-details-frame-1171277318">
              <div>
                {/* Title */}
                <div className="ideas-details-title">{idea.title || 'Title not available'}</div>

                <div className="ideas-details-frame-name_category">
                  <div className="ideas-details-frame-1171275589">
                    {/* Author and Occupation */}
                    <div className="ideas-details-name">{idea.user.name || 'Author unknown'}</div>
                    <div className="ideas-details-occupation">
                      {idea.user.occupation || 'Occupation unknown'}, {idea.user.currentCity || 'City unknown'}
                    </div>
                  </div>

                  <div className="ideas-details-line-10"></div>

                  <div className="ideas-details-frame-1171275591">
                    {/* Category */}
                    <div className="ideas-details-category">Category</div>
                    <div className="ideas-details-category-details">{idea.category || 'No category assigned'}</div>
                  </div>

                  <div className="ideas-details-line-11"></div>

                  <div className="ideas-details-frame-1171275590">
                    {/* Submission Date */}
                    <div className="ideas-details-submitted-on">Submitted on</div>
                    <div className="ideas-details-_11-jun-2024">{new Date(idea.createdAt).toLocaleDateString() || 'Unknown date'}</div>
                  </div>
                </div>
              </div>
              <div className="ideas-details-line-102"></div>
            </div>
            <div className="ideas-details-frame-1171277320">
              {/* Description */}
              <div className="ideas-details_1">{idea?.description?.split('\n')[0] || 'No description available'}</div>

              {/* Image */}
              <div className="ideas-details-frame-1171277505">
                {idea.image && <img src={`${process.env.REACT_APP_BACKEND}/${idea.image}`} alt={idea?.title} />}
                <div className="ideas-details_2">{idea?.description?.split('\n')[1] || ''}</div>
              </div>

              <div className="ideas-details_1">{idea?.description?.split('\n').slice(2).join('\n') || ''}</div>
            </div>
          </div>
        </div>

        <div className="ideas-details-right-div">
          <div className="ideas-details-frame-1171277507">
            <div className="ideas-details-frame-1171277508">
              <div className="ideas-details-frame-1171277506">
                <div className="ideas-details-frame-1171275395">
                  <div className="detail-vote-count-top">
                    <p className='votecast-text'>Total Vote Cast </p>
                    <p className='votecast-number'>{totalVotes || 0}</p>
                  </div>
                  <div className="detail-vote-count-bottom">
                    <div className="detail-vote-count-bottom-left">
                      <p className='votecast-text'>Total Agree </p>
                      <p className='votecast-number'>{totalLikes || 0}</p>
                    </div>
                    <div className="ideas-details-line-10"></div>
                    <div className="detail-vote-count-bottom-right">
                      <p className='votecast-text'>Total DisAgree </p>
                      <p className='votecast-number'>{totalDislikes || 0}</p>
                    </div>
                  </div>
                </div>

                {/* Vote and Dislike Buttons */}
                {!userInfo ?
                  (
                      <button className='ideas-details-sign-in' onClick={()=>handleSignIn()}>Sign in to vote this idea</button>
                  ):
                  (
                  <div className='voto-buttons'>
                    {(votedTheIdea || hasVoted) && isLiked ? (
                      <div className="like-vote-button-gif" style={{ backgroundColor: 'transparent' }}>
                        <img src="/detailsPage/vote.gif" className="img-vite-gif" alt="Already voted" />
                      </div>
                    ) : (
                      hasVoted && isDisLiked ? 
                      (<></>):
                      (
                      <div className={`like-vote-button ${hasVoted ? 'disabled-btn-already-voted' : ''}`} onClick={() => handleVote('like')}>
                        {userInfo ? (
                          <div>{hasVoted && isLiked ? 'Already Voted' : !isLiked && !isDisLiked ? 'Agree' : ""}</div>
                        ) : (
                         <></>
                        )}
                      </div>
                      )
                    )}
  
  
                    {(votedTheIdea || hasVoted) && isDisLiked ? (
                      <div className="like-vote-button-gif" style={{ backgroundColor: 'transparent' }}>
                        <img src="/detailsPage/dislike.gif" className="img-vite-gif" alt="Already voted" />
                      </div>
                    ) : (
                      hasVoted && isLiked ? 
                      (<></>):
                      (
                      <div className={`like-vote-button ${hasVoted ? 'disabled-btn-already-voted' : ''}`} onClick={() => handleVote('dislike')}>
                        {userInfo ? (
                          <div>{hasVoted && isDisLiked ? 'Already Voted' : !isLiked && !isDisLiked ? 'Disagree' : ""}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      )
                    )}
  
                   
                  </div>)
                }
              </div>

              {/* </div> */}

              {/* Monthly Vote Limit Info */}
              <div className="ideas-details-vote-per-month">
                <span>
                  <span className="ideas-details-vote-per-month-span">You can vote</span>
                  <span className="ideas-details-vote-per-month-span2"> {20} </span>
                  <span className="ideas-details-vote-per-month-span3">ideas per month.</span>
                </span>
              </div>
              {userInfo && (
                <div >
                  <span>
                    {/* {loadingUserTotalVoteCount, errorUserTotalVoteCount, userInfoVoteCount} */}
                    <span className="ideas-details-vote-per-month-span">You have</span>
                    <span className="ideas-details-vote-per-month-span2"> {loadingUserTotalVoteCount ? <span>Loading</span>
                      : errorUserTotalVoteCount ? <span>Something went wrong!</span> :
                        totalVoteCountUniqueUser} </span>
                    <span className="ideas-details-vote-per-month-span3">votes left</span>
                  </span>
                </div>
              )}

            </div>
            <SocialIdeaDetails />
          </div>

          {/* Report Section */}
          <div className="ideas-details-finish-2164609-1">
            <img src="/detailsPage/reports.png" width={'7%'} alt="Report icon" />
            <div className="ideas-details-report-this-idea" onClick={openReportModal} disabled={hasReported}>
              {hasReported ? 'Already Reported' : 'Report this idea'}
            </div>
          </div>
        </div>
      </div >

      {/* Report Modal */}
      {
        showReportModal && (
          <div>
            <div className="modal-background-overlay"></div>
            <div className="report-modal">
              <button className="close-btn" onClick={closeReportModal}>
                &times;
              </button>
              <h2 className="report-this-idea-title">Report this idea</h2>

              <div className="report-main-div">
                <div className="report-option" onClick={() => setReportType('Spam')}>
                  {reportType === 'Spam' ? selectRadiobtnDiv() : <div className={`ellipse`}></div>}
                  <div>Spam</div>
                </div>
                <div className="report-option" onClick={() => setReportType('Inappropriate Content')}>
                  {reportType === 'Inappropriate Content' ? selectRadiobtnDiv() : <div className={`ellipse`}></div>}
                  <div>Inappropriate Content</div>
                </div>
                <div className="report-option" onClick={() => setReportType('Misleading or False Information')}>
                  {reportType === 'Misleading or False Information' ? selectRadiobtnDiv() : <div className={`ellipse`}></div>}
                  <div>Misleading or False Information</div>
                </div>
                <div className="report-option" onClick={() => setReportType('Plagiarism')}>
                  {reportType === 'Plagiarism' ? selectRadiobtnDiv() : <div className={`ellipse`}></div>}
                  <div>Plagiarism</div>
                </div>
                <div className="report-option" onClick={() => setReportType('Violation of Terms')}>
                  {reportType === 'Violation of Terms' ? selectRadiobtnDiv() : <div className={`ellipse`}></div>}
                  <div>Violation of Terms</div>
                </div>
                <div className="report-option" onClick={() => setReportType('Other')}>
                  {reportType === 'Other' ? selectRadiobtnDiv() : <div className={`ellipse`}></div>}
                  <div>Other</div>
                </div>
                {reportType === 'Other' && (
                  <textarea value={customReport} onChange={(e) => setCustomReport(e.target.value)} placeholder="Write here..." />
                )}
                <button className="handleReportSubmitbtn" type="button" onClick={handleReportSubmit}>
                  Submit
                </button>
              </div>

            </div>
          </div>
        )
      }
    </>
  );
};

export default IdeaDetails;
