import React, { useState } from 'react';
import { PeivacyPolicyData } from "./data"
import "../termsAndConditions/termsAndConstions.css"
import PrivacyPolicyHeader from './PrivacyPolicyHeader';


const PrivacyPolicyMain = () =>{
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return(
        <div>
        <PrivacyPolicyHeader/>
        <div className="terms-container">
        {PeivacyPolicyData.map((policy, index) => (
            <div key={index} className="term-item">
                <div className="term-title" onClick={() => handleToggle(index)}>
                    {activeIndex === index? <h4 style={{color: "#4548e3"}}>{policy.title}</h4>: <h4>{policy.title}</h4>}
                    
                    <span className='arrow-terms'>{activeIndex === index ? '' : <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 1L8.5 8L1.5 15" stroke="#4548E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    }</span>
                </div>
                {activeIndex === index && (
                    <div className="term-details">
                        {/* <p className='term-title_details'>{term.title}</p> */}
                  
                            <>
                             <p className='terms_details_main'>{policy.details}</p>
                             {policy.listData && 
                                policy.listData.map((listData, indexListData) => (
                                    <>
                                        <ul className='terms_details_main' style={{lineHeight: "8px"}} key={indexListData}>
                                            <li>{listData}</li>
                                        </ul>
                                    </>
                                ))
                             }

                            {policy.moreInfo &&
                                <p className='terms_details_main'>{policy.moreInfo}</p> 
                            }
                            
                            </>
                      
                        
                    
                    </div>
                )}
            </div>
        ))}
    </div>
    </div>
    )
}

export default PrivacyPolicyMain;