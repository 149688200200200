import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './css/Profile.css'; // Add a custom CSS file for styling
import axios from 'axios';
import dayjs from 'dayjs'; // Import for date formatting
import InformingUser from './Modal/Inform';
import { TextField, InputAdornment, Radio, RadioGroup, FormControlLabel, Button } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WorkIcon from '@mui/icons-material/Work';
import PublicIcon from '@mui/icons-material/Public';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';

const Profile = () => {
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [userIdeas, setUserIdeas] = useState(null);
  const [userVotes, setUserVotes] = useState(null);
  const [userReports, setUserReports] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    occupation: '',
    currentCity: '',
    dob: '',
    country: ''
  });
  const [stats, setStats] = useState({
    totalIdeas: 0,
    totalVotes: 0,
    totalReports: 0,
  });
  const [showModal, setShowModal] = useState(false); // State for modal
  const [modalData, setModalData] = useState([]); // State for modal data (ideas, votes, or reports)
  const [modalType, setModalType] = useState(''); // To differentiate between ideas, votes, and reports
  const navigate = useNavigate(); // To navigate to specific idea details
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [liveInBangladesh, setLiveInBangladesh] = useState(null); // Track if user lives in Bangladesh
  const [alertModalShow, setAlertModalshow] = useState(false);
  const [alertModalContent, setAlertModalContent] = useState(false);
  const [dobInputType, setDobInputType] = useState('text');

  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page') || "";
  const ideaId = queryParams.get('idea') || "";
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/user/get-user-data`, {
          withCredentials: true,
        });
        if (response.status === 401) {
          throw new Error('Unauthorized');
        }
        const { userProfile, ideas = [], votes = [], reports = [] } = response.data;
        const formattedDob = userProfile.dob ? dayjs(userProfile.dob).format('D MMMM, YYYY') : '';

        // Set user data and form data
        setUserData(userProfile);
        setUserIdeas(ideas);
        setUserVotes(votes);
        setUserReports(reports);
        setFormData({
          name: userProfile.name,
          occupation: userProfile.occupation,
          currentCity: userProfile.currentCity,
          dob: formattedDob,
          country: userProfile.country
        });
        setLiveInBangladesh(userProfile.country === 'Bangladesh' ? 'Yes' : 'No');
        setStats({
          totalIdeas: ideas.length || 0,
          totalVotes: votes.length || 0,
          totalReports: reports.length || 0,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setAlertModalshow(true);
          setAlertModalContent("Your session has expired. Please log in again.");
        } else {
          console.error('Error fetching user data:', error);
        }
      }
    };
    fetchUserData();
  }, [navigate]);

  const handleDobFocus = () => {
    setDobInputType('date');
  };

  const handleDobBlur = () => {
    if (formData.dob) {
      // Format the date using dayjs
      const formattedDate = dayjs(formData.dob).format('D MMMM, YYYY');
      setFormData((prevState) => ({
        ...prevState,
        dob: formattedDate,
      }));
    }
    setDobInputType('text'); // Always reset to text after blur
  };


  const handleRouteNextPage = (e, ideaId) => {
    e.preventDefault()
    navigate("/idea/" + ideaId);
  }
  // Handle input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const finalCountry = liveInBangladesh === 'Yes' ? 'Bangladesh' : formData.country;
    // Prepare the form data to be sent
    const updatedFormData = {
      ...formData,
      currentCity: formData.currentCity,
      country: finalCountry,
    };

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/user/update-profile`, updatedFormData, {
        withCredentials: true, // Pass credentials for authentication
      });
      setUserData(response.data.user);
      setIsEditing(false); // Exit editing mode
      setShowSuccessPopup(true); // Show success popup

      setTimeout(() => {
        if (page === 'pitch') {
          navigate('/pitch');  // Redirect to /pitch if page=pitch
        } else if (page === 'idea') {
          navigate(`/idea/`+ideaId);  // Redirect to /idea/{ideaId} if page=idea and ideaId is available
        } else {
          navigate('/');  // Redirect to / if no other conditions are met
        }
      }, 2000);

    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  // Toggle editing mode
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleShowModal = async (type, data) => {
    setModalType(type); // Set the modal type

    if (type === 'Ideas') {
      // If it's ideas, just show the ideas directly
      setModalData(data);
      setShowModal(true);
    } else {
      // For Votes and Reports, fetch the idea details based on ideaId
      const fetchedIdeas = await Promise.all(
        data.map(async (item) => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/all/ideas/${item.ideaId}`);
            return response.data; // Assuming the response returns the full idea data
          } catch (error) {
            console.error('Error fetching idea details:', error);
            return null; // If error, return null or handle it accordingly
          }
        })
      );
      const validIdeas = fetchedIdeas.filter((idea) => idea !== null);
      setModalData(validIdeas);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData([]);
  };

  const handleNavigateToIdea = (ideaId) => {
    navigate(`/idea/${ideaId}`);
  };
  if (alertModalShow && !userData) {
    navigate('/login');
    return (
      <>
        {alertModalShow && (
          <InformingUser
            modalContents={alertModalContent}
            setModalShow={setAlertModalshow}
          />
        )}
        <div>Loading...</div>
      </>
    );
  }
  
  return (
    <div>
      {/* Success popup */}
      {showSuccessPopup && (
        <div className="success-popup">
          {page === 'pitch' ? "Profile updated successfully! redirecting to pitch idea..."
          : page === 'idea' ? "Profile updated successfully! redirecting to the idea details..."
          : "Profile updated successfully! redirecting to home..."
          }  
        </div>
      )}

      <div className="profile-container-box">
        <div class="additional-information_user-profile">Additional Information</div>
        <div className="profile-info">
          <div style={{ padding: '20px' }}>
            <div>
              <label htmlFor="name" className='pitchIdeaLabels'>
                Display Name*
              </label>
              <TextField
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>


            {/* Occupation Field with Icon */}
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="occupation" className='pitchIdeaLabels' style={{marginTop:"30px"}}>
                Occupation*
              </label>
              <TextField
                id="occupation"
                name="occupation"
                value={formData.occupation}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WorkIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>


            {/* Date of Birth Field with Icon */}
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="dob" className='pitchIdeaLabels' style={{marginTop:"30px"}}>
                Date of Birth*
              </label>
              <TextField
                id="dob"
                name="dob"
                type={dobInputType}
                value={formData.dob}
                onChange={handleInputChange}
                onFocus={handleDobFocus}
                onBlur={handleDobBlur}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
             

            {/* Living in Bangladesh Radio Group */}
            <div style={{ marginBottom: '15px' }}>
              <label className='pitchIdeaLabels' style={{marginTop:"30px"}}>Are you currently living in Bangladesh?*</label>
              <RadioGroup
                row
                name="liveInBangladesh"
                value={liveInBangladesh}
                onChange={(e) => setLiveInBangladesh(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </div>

            {/* Conditional Input Fields based on living in Bangladesh */}
            {liveInBangladesh === 'No' && (
              <div className='country-city-input'>

                {/* Current Country */}
                <div style={{ flexGrow:1, marginBottom: '15px' }}>
                  <label htmlFor="country" className='pitchIdeaLabels'>
                    Current Country*
                  </label>
                  <TextField
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PublicIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                {/* Current City */}
                <div style={{ flexGrow:1,marginBottom: '15px' }}>
                  <label htmlFor="currentCity" className='pitchIdeaLabels'>
                    Current City*
                  </label>
                  <TextField
                    id="currentCity"
                    name="currentCity"
                    value={formData.currentCity}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

              </div>
            )}
            {liveInBangladesh === 'Yes' && (
              <div style={{ display: 'flex', gap: '15px', marginBottom: '15px' }}>
              {/* Current City */}
              <div style={{ flexGrow: 1 }}>
                <label htmlFor="currentCity" className='pitchIdeaLabels'>
                  Current City*
                </label>
                <TextField
                  id="currentCity"
                  name="currentCity"
                  value={formData.currentCity}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
    
            )}
            {/* Full-Width Button for Update Profile */}
            <button
              onClick={handleFormSubmit}
              fullWidth
              className='submitBTNprofile'
            >
              Update your Profile
            </button>
          </div>
        </div>




        {/* Display user statistics */}
        {/* <div className="user-stats">
        <div class="additional-information_user-profile">User Statistics</div>
     
        <p><div className="label_userProfie">Total Idea Posted: {stats.totalIdeas}</div></p>
        <p><div className="label_userProfie">Total Vote Cast: {stats.totalVotes}  </div></p>
        <p><div className="label_userProfie">Total Reports: {stats.totalReports}</div></p>
      
       
        
          <div className="associated-buttons-container">
          <button onClick={() => handleShowModal('Ideas', userIdeas || [])}> Ideas</button>
          <button onClick={() => handleShowModal('Votes', userVotes || [])}> Votes</button>
          <button onClick={() => handleShowModal('Reports', userReports || [])}> Reports</button>
        </div>
      </div> */}

        {/* Modal for displaying associated data */}
        {showModal && (
          <>
            <div className="modal">

              <button className="modal-close-btn" onClick={handleCloseModal}>&times;</button>
              <h3>{modalType}</h3>
              <div className="profile-modal-background-blur"></div>
              <ul>
                {modalData.length > 0 ? (
                  modalData.map((item, index) => (

                    <>

                      <div key={index}
                        onClick={(e) => handleRouteNextPage(e, item._id)}>
                        <div className="frame-1171277412"
                          style={{
                            backgroundColor:
                              index == 0 ? "rgba(25, 168, 166, 0.1)" :
                                index == 1 ? "rgba(32, 125, 213, 0.1)" :
                                  index == 2 ? "rgba(32, 35, 213, 0.1)" :
                                    index == 3 ? "rgba(119, 32, 213, 0.1)" :
                                      index == 4 ? "rgba(210, 32, 213, 0.1)" :
                                        index == 5 ? "rgba(213, 32, 126, 0.1)" :
                                          index == 6 ? "rgba(250, 120, 97, 0.1)" :
                                            index == 7 ? "rgba(236, 165, 34, 0.1)" :
                                              index == 8 ? "rgba(188, 186, 56, 0.1)" :
                                                index == 9 ? "rgba(25, 168, 95, 0.1)" :
                                                  "rgba(224, 231, 235, 0.5)" // Set background color based on index
                          }}
                        >
                          <div className="Profile-frame-1171277400">
                            <div className="frame-1171277407">
                              <div className="frame-1171277408">
                                <div className="train-1">
                                  <img className="group" src="group0.svg" />
                                  <img className="group2" src="group1.svg" />
                                  <img className="group3" src="group2.svg" />
                                  <img className="group4" src="group3.svg" />
                                  <img className="group5" src="group4.svg" />
                                  <img className="group6" src="group5.svg" />
                                  <img className="group7" src="group6.svg" />
                                  <img className="group8" src="group7.svg" />
                                  <img className="group9" src="group8.svg" />
                                </div>
                              </div>
                              <div className="frame-120_main">
                                <div className="transportation">{item.category.toUpperCase()}</div>
                                <div className="i-want-to-change-the-traffic-system-in-dhaka">
                                  {item.title || 'Untitled'}
                                </div>
                              </div>
                            </div>
                            <div className="frame-11712774082">
                              <div className="line-18"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <li>No data available</li>
                )}
              </ul>
            </div>
          </>
        )}

      </div>

    </div>
  );
};

export default Profile;
