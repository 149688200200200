import { useState } from "react";
import { Link } from "react-router-dom";
import '../css/Leaderboard.css';

const MobileVersionPitchAndProfilebtn = () => {
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('PoribortonchaiUserData')));
    return(
        <div className='mobile_pitch-insertion'>
        {userInfo ?
          <div className='mbl_side_by_side'>
            <Link to="/profile" className="pitch-button_signed_in_pitch-button_mbl" style={{ backgroundColor: "#ffffff" }}>
              <div class="frame-1171277485">
                <img class="rectangle-303" src={userInfo.profilePicture ? userInfo.profilePicture : '/UserDetails/userNotFound.png'} />
                <div class="profile">Profile</div>
              </div>
            </Link>
            <div>
              <Link to="/pitch" className="pitch-button_signed_in_pitch-button_mbl">Pitch my idea</Link>
            </div>
          </div>
          :
          <div>
            <Link to={`${process.env.REACT_APP_BACKEND}/auth/google`}
              className="pitch-button_signed_in_pitch-button_mbl">
              Pitch my idea
            </Link>
          </div>
        }
      </div>
    )
}

export default MobileVersionPitchAndProfilebtn;