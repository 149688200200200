import React from 'react';
import './css/Footer.css'; // Create the Footer.css file for styling

const Footer = () => {
  return (

    <div class="footer-main">
      <div class="footer-container">
        <div className='footer-left'>
          <div className='footer-left-left'>
            <a href="https://www.facebook.com/hubxpert" target="_blank" rel="noopener noreferrer">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.4963 0.738281H2.47919C1.79378 0.738693 1.23814 1.2946 1.23828 1.98029V21.9974C1.23869 22.6828 1.7946 23.2384 2.48029 23.2383H13.2573V14.5371H10.335V11.1313H13.2573V8.62495C13.2573 5.71838 15.0318 4.13635 17.6245 4.13635C18.8664 4.13635 19.9336 4.22891 20.2446 4.27025V7.30811H18.4567C17.0461 7.30811 16.7729 7.97841 16.7729 8.96223V11.1313H20.1458L19.7063 14.5371H16.7729V23.2383H22.4963C23.1821 23.2384 23.7381 22.6826 23.7383 21.9968C23.7383 21.9967 23.7383 21.9966 23.7383 21.9963V1.97919C23.738 1.29378 23.182 0.738144 22.4963 0.738281Z" fill="#2E3A59" />
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/hubxpert/" target="_blank" rel="noopener noreferrer">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.96687 1.21875C2.317 1.21875 1.23828 2.30214 1.23828 3.7261C1.23828 5.11862 2.28487 6.23291 3.90356 6.23291H3.93488C5.61702 6.23291 6.66388 5.11862 6.66388 3.7261C6.63243 2.30214 5.61702 1.21875 3.96687 1.21875Z" fill="#2E3A59" />
                <path d="M1.52344 8.21387H6.34671V22.7249H1.52344V8.21387Z" fill="#2E3A59" />
                <path d="M18.1853 7.87354C15.5833 7.87354 13.8385 10.3185 13.8385 10.3185V8.21411H9.01514V22.7251H13.8383V14.6216C13.8383 14.1878 13.8697 13.7547 13.9972 13.4444C14.3458 12.5782 15.1393 11.6807 16.4718 11.6807C18.2171 11.6807 18.9152 13.0114 18.9152 14.9622V22.7251H23.7381V14.4048C23.7381 9.94762 21.3584 7.87354 18.1853 7.87354Z" fill="#2E3A59" />
              </svg>
            </a>
            <a href="https://twitter.com/hubxpert_crm" target="_blank" rel="noopener noreferrer">
              <svg width="25" height="24" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3076 9.28309L18.3808 0.814453H16.7046L10.563 8.16765L5.65769 0.814453H0L7.41779 11.9338L0 20.8145H1.67621L8.16194 13.0492L13.3423 20.8145H19L11.3072 9.28309H11.3076ZM9.01178 12.0318L8.2602 10.9245L2.28017 2.11413H4.85474L9.68069 9.22439L10.4323 10.3316L16.7054 19.5739H14.1309L9.01178 12.0322V12.0318Z" fill="#2E3A59" />
              </svg>
            </a>
            <a href="https://www.youtube.com/@hubxpertcrm" target="_blank" rel="noopener noreferrer">
              <svg width="25" height="24" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.2747 3.37619C22.0154 2.41228 21.2554 1.65244 20.2916 1.39288C18.5309 0.911133 11.488 0.911133 11.488 0.911133C11.488 0.911133 4.44536 0.911133 2.68466 1.37462C1.73943 1.6339 0.960907 2.41241 0.70163 3.37619C0.238281 5.13675 0.238281 8.78792 0.238281 8.78792C0.238281 8.78792 0.238281 12.4575 0.70163 14.1996C0.961182 15.1634 1.72089 15.9233 2.6848 16.1828C4.4639 16.6647 11.4883 16.6647 11.4883 16.6647C11.4883 16.6647 18.5309 16.6647 20.2916 16.2012C21.2555 15.9418 22.0154 15.182 22.2749 14.2182C22.7381 12.4575 22.7381 8.80646 22.7381 8.80646C22.7381 8.80646 22.7567 5.13675 22.2747 3.37619ZM9.2457 12.161V5.41484L15.1022 8.78792L9.2457 12.161Z" fill="#2E3A59" />
              </svg>
            </a>
          </div>


          <div className='footer-left-middle'></div>



          <div className='footer-left-right'>
            <span>Powered by <a href='https://hubxpert.com'>Hubxpert</a></span>
          </div>
        </div>




        <div class="footer-right">
          <div className="footer-right-1">
            <a href='/terms_and_conditions'>Terms & Conditions</a>
          </div>
          <div className="footer-right-2">
          </div>
          <div className="footer-right-3">
            <a href='/privacy_policy'>Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Footer;
