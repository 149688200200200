// src/store.js
import { legacy_createStore as createStore } from 'redux';
import {  applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from "@redux-devtools/extension";
import {thunk} from 'redux-thunk';
import { UserGoogleInfoReducer, userInfoReducer, userTotalVoteCountReducer } from './reducers/UserReducer';
import { AllCaregoryInfoReducer } from './reducers/CategoryReducer';
import { FetchTopIdeasReducer, IdeasCountInfoReducer, IdeasFilteredInfoReducer } from './reducers/IdeaReducer';

const initialState = {

};

const rootReducer = combineReducers({
  // Add other reducers here
  userInfo: userInfoReducer,
  UserGoogleInfo: UserGoogleInfoReducer,
  AllCaregoryInfo: AllCaregoryInfoReducer,
  FilteredIdeas: IdeasFilteredInfoReducer,
  TopIdeas: FetchTopIdeasReducer,
  IdeasCountInfo: IdeasCountInfoReducer,
  userTotalVoteCount: userTotalVoteCountReducer
});

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;