import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/Navbar.css';
import Avatar from '@mui/material/Avatar';
import { throttle } from 'lodash';

const Navbar = () => {
  const dropdownRef = useRef(null);
 
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('PoribortonchaiUserData')));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const getUserFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const userParam = urlParams.get('user');
    
    if (userParam) {
      try {
        const userObject = JSON.parse(decodeURIComponent(userParam));
        return userObject;
      } catch (error) {
        console.error('Error parsing user data after Google redirection:', error);
      }
    }
    return null;
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const userData = getUserFromUrl();
    const ideaParam = new URLSearchParams(window.location.search).get('idea') || "";
    const additionalInfoParam = new URLSearchParams(window.location.search).get('additionalInfo') || "";

    if (userData && !localStorage.getItem('PoribortonchaiUserData')) {
      localStorage.setItem('PoribortonchaiUserData', JSON.stringify(userData));
      setUserInfo(userData); 

      if (additionalInfoParam === 'not_found') {
        navigate("/profile");
      } else if (ideaParam !== 'undefined' && ideaParam) {
        navigate(`/${ideaParam}`);
      } else {
        navigate("/pitch");
      }
    }


    // Function to handle clicks outside of the dropdown
  function handleClickOutside(event) {
    if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  }

  // Bind the event listener
  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    // Unbind the event listener on clean up
    document.removeEventListener('mousedown', handleClickOutside);
  };
  }, [dropdownOpen]); // Only run this effect once on mount

  const handleLogout = () => {
    localStorage.removeItem('PoribortonchaiUserData');
    setUserInfo(null);
    navigate('/'); // Use navigate for internal routing
  };
  
  const throttledAvatar = useCallback(throttle(() => {
    return (
      <Avatar
        className="ellipse-2750"
        loading="lazy"
        src={userInfo?.profilePicture || '/UserDetails/userNotFound.png'}
        onClick={toggleDropdown}
      />
    );
  }, 5000), [userInfo, toggleDropdown]);

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/" style={{ textDecoration: "none" }}>
          <img src="/logo.png" alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="nav">
        {userInfo ? (
          <div className="profile-container">
            <div>
              {throttledAvatar()}
              <div className={`dropdown ${dropdownOpen ? 'show' : ''}`} ref={dropdownRef}>
                <ul className="dropdown-menu" onClick={() => setDropdownOpen(false)}>
                  <li>
                    <Link to="/profile">
                      <div className="nav_frame-1171277397">
                        <div className="my-ideas">My Profile</div>
                      </div>
                    </Link>
                  </li>
                  <li><hr className="Nav-dropdown-li-divider" /></li>
                  <li onClick={handleLogout}>
                    <div className="nav_frame-1171277398">
                      <div className="log-out">Log out</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <Link to="/pitch" className="pitch-button signed_in_pitch-button">Pitch my idea</Link>
            </div>
          </div>
        ) : (
          <div>
            <Link to={`${process.env.REACT_APP_BACKEND}/auth/google`} className="pitch-button signed_in_pitch-button">
              Pitch my idea
            </Link>
          </div>
        )}
      </div>
    </header>
  );
}

export default Navbar;
