import { useState } from 'react';
import './css/Leaderboard.css';
import VotersList from './VotersList';
import LeaderBoardListMobile from './LeaderBoardListMobile';

const LeaderboardList = (props) => {
  const [hover, setHover] = useState(false);
  const voteIndex = props.index + 1;
  const getBackgroundColor = () => {
    return props.index == 0 ? "rgba(25, 168, 166, 0.1)" :
      props.index == 1 ? "rgba(32, 125, 213, 0.1)" :
        props.index == 2 ? "rgba(32, 35, 213, 0.1)" :
          props.index == 3 ? "rgba(119, 32, 213, 0.1)" :
            props.index == 4 ? "rgba(210, 32, 213, 0.1)" :
              props.index == 5 ? "rgba(213, 32, 126, 0.1)" :
                props.index == 6 ? "rgba(250, 120, 97, 0.1)" :
                  props.index == 7 ? "rgba(236, 165, 34, 0.1)" :
                    props.index == 8 ? "rgba(188, 186, 56, 0.1)" :
                      props.index == 9 ? "rgba(25, 168, 95, 0.1)" :
                        "rgba(224, 231, 235, 0.5)"
  };
  const getHoverColor = () => {
    return props.index == 0 ? "#19a8a6" :
      props.index == 1 ? "#207dd5" :
        props.index == 2 ? "#2023d5" :
          props.index == 3 ? "#7720d5" :
            props.index == 4 ? "#7720d5" :
              props.index == 5 ? "#d5207e" :
                props.index == 6 ? "#eca522" :
                  props.index == 7 ? "#d57720" :
                    props.index == 8 ? "#bcba38" :
                      props.index == 9 ? "#19a85f" :
                        "#2e3a59";
  };
  return (
    <>

      <div className="frame-1171277412"
        style={{
          backgroundColor: hover ? getHoverColor() : getBackgroundColor(),
          transition: 'background-color 0.3s ease'
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <LeaderBoardListMobile
          data={{
            data: props.data,
            color: getHoverColor(),
          }}
        />
        <div className="frame-1171277400">
          <div className="frame-1171277407">
            <div>
              <div className='imageIcon' style={!hover ? { backgroundColor: getHoverColor() } :
                { backgroundColor: "white" }}>
                {
                  !hover ? (<img src={`/category_images/${props.data.category.toLowerCase()}.png`} width={"80%"} />)
                    :
                    (<span className='index-gloock-regular' style={{ color: getHoverColor() }}>{voteIndex < 10 ? "0" : ""}{voteIndex}</span>)
                }
              </div>
            </div>
            <div className="frame-120_main">
              <div className="transportation" style={{ color: hover && "#ffffff" }}>{props.data.category.toUpperCase()}</div>
              <div className="i-want-to-change-the-traffic-system-in-dhaka" style={{ color: hover && "#ffffff" }}>
                {props.data.title}
              </div>
            </div>
          </div>
          {hover ?
            <div className="frame-11712774082">
              <div className="line-18" style={{ backgroundColor: "#ffffff", height: "2.5px" }}></div>
              <button className="list_btn_frame-1171275241">
                <div className="list_btn_view-details-to-vote">View details to Vote</div>
              </button>

            </div> :
            <div className="frame-11712774082">
              <div className="line-18"></div>
              {props.data.votes.length > 0 &&
                props.data.votes.slice(0, 6).map((voteData) => (
                  <VotersList style={{ color: hover && "#ffffff" }}
                    profilePicture={voteData.profilePicture} />
                ))

              }

              {props.data.votes.length > 0 &&
                <>
                  <div className="VoterListellipse" style={{
                    transition: 'background-color 0.3s ease' // Smooth transition for hover effect
                  }}>
                    <span className='voteLengthLeaderboardList' style={{ backgroundClip: getBackgroundColor() }}>
                      {props.data.votes.length > 0 && props.data.votes.length + "+ Voted"}
                    </span>
                  </div>
                </>
              }

            </div>
          }
        </div>
      </div>
    </>
  )
}

export default LeaderboardList;