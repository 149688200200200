import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import IdeaDetailsPage from './pages/IdeaDetailsPage';
import SignInPage from './pages/SignInPage';
import PitchPage from './pages/PitchPage';
import ProfilePage from './pages/ProfilePage';
import MobileHeader from './components/MobileHeader';
import TermsAndConditionsMain from './components/termsAndConditions/TermsAndConditionsMain';
import PrivacyPolicyMain from './components/Privacy_policy/PrivacyPolicyMain';

function App() {
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('PoribortonchaiUserData')));
  useEffect(()=>{
      setUserInfo(localStorage.getItem('PoribortonchaiUserData'));
  },[userInfo])

  return (
    <Router>
      <div className="app">
        <Navbar />
        <MobileHeader/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/idea/:id" element={<IdeaDetailsPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/login" element={<SignInPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/terms_and_conditions" element={<TermsAndConditionsMain />} />

          <Route path="/privacy_policy" element={<PrivacyPolicyMain />} />
          
          {userInfo &&
            <Route path="/pitch" element={<PitchPage />} />
          }
        </Routes>
          <Footer /> 
      </div>
    </Router>
  );
}

export default App;
